
<template>
  <comprehensive>
    <div
      style="background: #f5f5f5;"
      v-loading="pageLoading"
    >
      <div class="title_img_div">
        <div class="div1">
          <div class="nav_box">
            <span @click="onGoHome">首页</span> > <span @click="onGoBid">集采专场</span> >
            <span>集采专场详情</span>
          </div>
          <div class="div2">
            <div class="div3">
              <div class="div4">
                <span>集采专场</span>
                <!--集采结束-->
                <!--评标完成 流标-->
                <!-- 1-未开始 2-集采中 3-集采结束/待评标 4-集采结束/评标审批中 5-集采结束/评标审批完成/已评 6-集采结束/评标审批完成/流标 7-集采结束/流标 -->
                <div
                  class="div5-67"
                  v-if="data&&data.orderStatus&&data.orderStatus.value == 2"
                ></div>
                <div
                  class="div5-5"
                  v-else
                ></div>
              </div>
              <div class="div6">专场编号 : {{ data.orderNo }}</div>
            </div>
            <div
              class="div7"
              v-if="data&&data.orderStatus&&data.orderStatus.value == 2"
            >
              <div class="div8">距离结束</div>
              <div class="div9">
                <span class="spanh5">{{ timeStr2 && !!timeStr2.day ? timeStr2.day : '' }}</span>天 <span
                  class="spanh5">{{ timeStr2 && !!timeStr2.hour ? timeStr2.hour : ''}}</span>时 <span
                  class="spanh5">{{ timeStr2 && !!timeStr2.minute ? timeStr2.minute : ''}}</span>分 <span
                  class="spanh5">{{ timeStr2 && !!timeStr2.second ? timeStr2.second : '' }}</span>秒
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title_text_div">
        <div class="div1">
          <div class="div2">
            <div class="div3">
              <div class="div4">报价方式:</div>
              <div class="div5">{{ data.sellerQuoteType.label }}</div>
            </div>
            <div class="div3">
              <div class="div4">交货日期:</div>
              <div class="div5">{{data.deliveryTime }}</div>
            </div>
            <div class="div3">
              <div class="div4">交货地点:</div>
              <div class="div5">{{ data.deliveryPlaceProvince }}{{data.deliveryPlaceCity}}{{data.deliveryPlaceArea}}{{data.deliveryPlaceAdress}}</div>
            </div>

          </div>

        </div>
        <div class="divr1">
          <div class="divr2">采购单位：{{ data.platformUnitName }}</div>
          <div class="divr3">
            <img src="./img/82646.svg" />
            <div class="divr4">
              <span>联系人:</span>
              <span style="text-align:left">{{ data.platformTraderName }}</span>
            </div>
            <div
              class="divr4"
              style="margin-top: 5px"
            >
              <span>联系电话:</span>
              <span style="text-align:left">{{ data.platformTraderPhone }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="divb1">
        <div
          class="divb2"
          v-if="type"
        >
          <div class="divb3"></div>
          报价物资
        </div>

        <div class="divb4">
          <table class="table table-hover">
            <tbody>
              <tr>
                <th>序号</th>
                <th>品名</th>
                <th>材质</th>
                <th>规格</th>
                <th>厂家</th>
                <th>计价方式</th>
                <th>数量单位</th>
                <th>
                  数量
                </th>
                <th>
                  重量
                </th>
              </tr>
              <tr
                v-for="(item,index) in dataDetails"
                :key="index"
              >
                <td>{{ index+1 }}</td>
                <td>{{ item.product }}</td>
                <td>{{ item.material }}</td>
                <td>{{ item.specifications }}</td>
                <td>{{ item.origin }}</td>
                <td>{{ item.measurementMethodId.label }}</td>
                <td>{{ item.quantityMeasureUnitId.label }}</td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.weight }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="
            width: 1120px;
            height: 50px;
            line-height: 50px;
            background-color: #f6f6f6;
            border: 1px solid #efefef;
            border-top: none;
            text-align: right;
          ">
          <span style="font-size: 14px; color: #666666; margin-right: 16px">合计</span>
          <span style="font-size: 14px; color: #999999; margin-right: 79px">数量
            <span style="color: #ef2147; font-weight: bold; font-size: 16px">{{data.totalQuantity}}</span></span>
          <span style="font-size: 14px; color: #999999; margin-right: 79px">重量
            <span style="color: #ef2147; font-weight: bold; font-size: 16px">{{data.totalWeight}}</span></span>
        </div>
      </div>

      <div style="width: 1120px;background-color: #ffffff;margin:0px auto;padding: 24px 40px;margin-bottom: 14px">
        <h4 style="margin: 0;font-size: 20px;color:#333333;border-bottom: 1px solid #efefef;padding-bottom: 22px;">
          评标结果公示
        </h4>
        <!-- 状态 1-未开始 2-集采中 3-集采结束/待评标 4-集采结束/评标审批中 5-集采结束/评标审批完联系人成/已评 6-集采结束/评标审批完成/流标 7-集采结束/流标 -->
        <div
          v-if="data&&data.orderStatus&&data.orderStatus.value == 5"
          style="width:581px;height: 185px;background: url(https://ddmallimg.ddmg.com/public/assets/img/newbid/772716.png) no-repeat center;margin:25px auto;"
        >
          <h4 style="color: #FF7E00;font-size: 20px;padding-left: 138px;padding-top: 70px;padding-right: 10px;">
            中标单位:
            {{data.sellerUnitName}}
          </h4>
          <h5 style="color: #999999;font-size: 14px;padding-left: 138px;padding-top: 10px;">公示时间 :{{data.auditDate}}</h5>
        </div>
        <div
          v-else
          style="width:581px;height: 185px;background: url(https://ddmallimg.ddmg.com/public/assets/img/newbid/772716.png) no-repeat center;margin:25px auto;"
        >
          <h4 style="color: #FF7E00;font-size: 20px;padding-left: 138px;padding-top: 70px;padding-right: 10px;">
            暂无中标单位
          </h4>
        </div>
      </div>

      <div style="width: 1200px; margin: 0 auto; background-color: #ffffff; ">
        <div style="padding: 16px 0px;font-size:12px;color: #ef2147;display: flex;flex-direction: row-reverse;justify-content: center;align-items: center">
          <div><img src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/right.svg"></div>
          <div style="height: 30px;line-height: 30px;display: inline-block;margin:0px 12px">交易三大服务保障</div>
          <div><img src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/left.svg"></div>
        </div>
        <div style="width: 1200px;display: flex;flex-direction: row-reverse;justify-content: space-around;align-items: center;padding-bottom:20px">
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text1.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">以诚为本</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">开拓创新、执行高效、追求完美</div>
            </div>
          </div>
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text2.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">资源保证</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">企业认证、诚信、会员三大体系保证</div>
            </div>
          </div>
          <div style="height: 41px;display: flex;justify-content: center">
            <img
              src="https://ddmallimg.ddmg.com/public/assets/img/index/c_p_special/title_text3.svg"
              style="width: 41px;height: 41px;"
            >
            <div style="margin-left: 20px">
              <div style="margin-top: 0px;font-size: 16px;color:#333333">货真价实</div>
              <div style="font-size: 12px;color: #999999;margin-top:8px">价格真实有效,杜绝虚假交易</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </comprehensive>
</template>

<script>
import comprehensive from "../../components/comprehensive.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: 'inquiryDetails',
  components: {
    comprehensive,
  },
  data () {
    return {
      dialogVisible: false,
      type: true,
      p_name: "",
      cd_name: "",
      jg_name: "",
      font_length: 0,
      offer_comment: "",
      dialogList: {},
      dialogWeight: "",
      dialogPrice: 0,


    };
  },
  created () {
    this.orderNo = this.$route.query.orderNo;
    if (this.$route.query.type == '1') {
      this.type = false;
    } else {
      this.type = true;
    }
    this.getInquiryDetailsInfo(this.orderNo);

  },
  methods: {
    ...mapActions("ddmg/bid", ["getInquiryDetailsInfo"]),
    onGoBid () {
      this.$router.push('/inquiry')
    },
    onGoHome () {
      this.$router.push('/index')
    },
    bidDetails (item) {
      this.dialogList = item;
      this.dialogVisible = true
    },
    //返回
    goback () {
      this.type = true;
    },
  },
  mounted () { },
  computed: {
    ...mapState("ddmg/bid", {
      data: (state) => state.inquiryDetailsInfo,
      dataDetails: (state) => state.inquiryDetails,
      timeStr2: (state) => state.timeStr,
      pageLoading: (state) => state.pageLoading,
    }),
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: 0;
}
select:focus {
  outline: 0;
}
textarea:focus {
  outline: 0;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.table tr th {
  width: 101px;
  height: 50px;
  padding: 0 !important;
  line-height: 50px !important;
  background-color: #f6f6f6;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  text-align: center;
}
.gojj {
  width: 292px;
  height: 40px;
  background-color: #ffffff;
  color: #ef2147;
  border: 1px solid #ef2147;
  font-size: 16px;
  line-height: 28px;
  border-radius: 4px;
  margin-bottom: 18px;
  cursor: pointer;
}
.spanh5 {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  line-height: 26px;
  color: #ff7e00;
  font-size: 14px;
  margin: 0px 5px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.title_text_div {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  top: -30px;
  height: 300px;
  .div1 {
    width: 763px;
    height: 300px;
    padding: 0px 24px;
    float: left;
    background-color: #ffffff;
    .div2 {
      margin-top: 37px;
      width: 758px;
      .div3 {
        margin: 12px auto;
        font-size: 14px;
        color: #333333;
        .div4 {
          display: inline-block;
          width: 110px;
          color: #333333;
          font-weight: 700;
        }
        .div5 {
          display: inline-block;
        }
        .div6 {
          font-size: 12px;
          color: #999999;
          margin-left: 15px;
          display: inline-block;
        }
      }
    }
  }
  .divr1 {
    width: 350px;
    height: 300px;
    float: right;
    padding-left: 30px;
    background-color: #ffffff;
    .divr2 {
      font-size: 18px;
      color: #333333;
      margin-top: 37px;
      font-weight: 600;
    }
    .divr3 {
      height: 70px;
      margin-top: 27px;
      img {
        width: 70px;
        height: 70px;
        float: left;
      }
      .divr4 {
        padding-left: 20px;
        font-size: 14px;
        color: #999999;
        float: left;
        width: 200px;
        margin-top: 12px;
        span {
          display: inline-block;
          width: 70px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
    .divr5 {
      width: 310px;
      height: 246px;
      margin-top: 50px;
      background: url(./img/2713.png);
      span {
        position: relative;
        left: 80px;
        top: 143px;
        font-size: 16px;
        color: #999999;
      }
    }
    .divr6 {
      width: 310px;
      height: 246px;
      margin-top: 50px;
      background: url(https://ddmallimg.ddmg.com/public/assets/img/newbid/271323.svg);
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 20px;
        color: #333333;
      }
    }
  }
}
.divb1 {
  width: 1120px;
  background-color: #ffffff;
  margin: 0px auto;
  padding: 24px 40px;
  margin-top: -16px;
  margin-bottom: 14px;
  .divb2 {
    margin: 0;
    font-size: 20px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 22px;
    font-weight: 700;
    color: #333333;
    display: flex;
    .divb3 {
      width: 3px;
      height: 18px;
      background-color: #ef2147;
      border-radius: 6px;
      position: relative;
      left: -8px;
      top: 4px;
    }
  }
  .divb4 {
    width: 1120px;
    overflow: hidden;
    .table {
      border: 1px solid #efefef;
      margin-bottom: 0;
      width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      tr th {
        vertical-align: top;
        border-top: 1px solid #ddd;
        height: 50px;
        padding: 0 !important;
        line-height: 50px !important;
        background-color: #fafafa;
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        border-color: #efefef !important;
        text-align: left !important;
        padding-left: 20px !important;
      }
      tr td {
        height: 50px;
        border-bottom: 1px solid #efefef;
        border-color: #efefef !important;
        line-height: 50px !important;
        padding: 0 !important;
        color: #333333;
        font-size: 14px;
        vertical-align: middle !important;
        text-align: left !important;
        padding-left: 20px !important;
      }
    }
  }
}
.title_img_div {
  width: 100%;
  height: 190px;
  background: url(./img/111562.png) no-repeat center;
  .div1 {
    width: 1200px;
    height: 190px;
    margin: 0 auto;
    padding-top: 17px;
    .nav_box {
      color: #ffffff;
      font-size: 14px;
      span {
        font-size: 14px;
        margin: 16px 0px;
        color: white;
        cursor: pointer;
      }
      span:hover {
        color: #ef2147ff;
      }
    }
  }
  .div2 {
    width: 1200px;
    height: 100px;
    .div3 {
      float: left;
      width: 70%;
      .div4 {
        font-size: 24px;
        color: #ffffff;
        margin-top: 33px;
        height: 32px;
        display: flex;
        align-items: center;
        span {
          font-size: 24px;
          line-height: 32px;
          color: #ffffff;
        }
        .div5-5 {
          height: 32px;
          background: url(./img/quoteEnd.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-67 {
          height: 32px;
          background: url(./img/quote.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-3 {
          height: 32px;
          background: url(./img/2702.png) no-repeat left;
          width: 155px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
        .div5-2 {
          height: 32px;
          background: url(./img/205687.png) no-repeat left;
          width: 82px;
          position: relative;
          margin-left: 10px;
          display: inline-block;
        }
      }
      .div6 {
        font-size: 14px;
        color: #ffffff;
        margin-top: 15px;
      }
    }
    .div7 {
      float: right;
      width: 300px;
      text-align: center;
      .div8 {
        color: #ffffff;
        font-size: 16px;
        margin-top: 18px;
        margin-bottom: 8px;
        font-weight: 700;
      }
      .div9 {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>
